import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import NewsArticleCard from "../components/NewsArticleCard"
import ThermalBackground from "../components/ThermalBackground"
import ConcaveBottom from "../components/ConcaveBottom"
import Cushion from "../components/ui/Cushion"
import Grid from "../components/ui/Grid"
import Space from "../components/ui/Space"
import Typography from "../components/ui/Typography"

const BlogIndex = ({
  data: { allKnowledgeCentreJson = [], contactDetailsJson } = {},
  location,
}) => {
  const articles = allKnowledgeCentreJson.edges.map(article => article.node)

  return (
    <Frame
      currentPathname={location.pathname}
      contactDetails={contactDetailsJson}
    >
      <SEO title="All posts" />
      <ThermalBackground>
        <ConcaveBottom fill="vLightGrey">
          <Cushion horizontal="medium" vertical="xxxxlarge">
            <Typography
              component="h2"
              preset="hero"
              color="white"
              align="center"
            >
              Knowledge Centre
            </Typography>
          </Cushion>
        </ConcaveBottom>
      </ThermalBackground>
      <Space top={-80}>
        <Cushion bottom="xxlarge" horizontal="medium">
          <Grid
            columns="1fr"
            columnGap="medium"
            rowGap="medium"
            responsive={[
              {
                minWidth: 500,
                props: { columns: "repeat(2, minmax(0, 1fr))" },
              },
              {
                minWidth: "tab",
                props: { columns: "repeat(3, minmax(0, 1fr))" },
              },
              {
                minWidth: "lap",
                props: { columns: "repeat(4, minmax(0, 1fr))" },
              },
            ]}
            style={{
              position: "relative",
              zIndex: 2,
            }}
          >
            {articles.map(({ title, image, snippet, fields: { slug } }) => (
              <Link to={slug}>
                <NewsArticleCard
                  image={image}
                  title={title}
                  snippet={snippet}
                />
              </Link>
            ))}
          </Grid>
        </Cushion>
      </Space>
    </Frame>
  )
}

BlogIndex.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}
export default BlogIndex

export const pageQuery = graphql`
  query {
    contactDetailsJson {
      email
      mobile
      landline
    }
    allKnowledgeCentreJson {
      edges {
        node {
          snippet
          image
          title
          fields {
            slug
          }
        }
      }
    }
  }
`
